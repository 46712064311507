const client = require('./request/client');
import useBugsnag from 'hooks/useBugsnag';
const { notifyBugsnag } = useBugsnag();

const URL = `${process.env.EP_URL}/client_api/batch_payment.php`;

const findBySrvrtid = async srvrtid => {
  const requestPayload = {
    request: 'search_merchant_services',
    api: "4.1",
    search_field: ['srvrtid'],
    search_value: [`${srvrtid}`],
  };

  return await client
    .sendRequest(URL, requestPayload)
    .then(response => {
      if (response.status !== 0) {
        const error = new Error(response.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response } } });
        return error;
      }
      const merchantService = response.output[0];

      if (!merchantService) {
        return new Error('Service not found');
      }
      return merchantService;
    })
    .catch(error => {
      notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response: error.response } } });
      return error;
    });
};

const search = async searchTerm => {
  const requestPayload = {
    request: 'search_merchant_services',
    api: "4.1",
    search_field: 'name',
    search_value: searchTerm,
  };

  return await client
    .sendRequest(URL, requestPayload)
    .then(response => {
      if (response.status !== 0) {
        const error = new Error(response.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response } } });
        return [];
      }
      return response.output;
    })
    .catch(error => {
      notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response: error.response } } });
      return error;
    });
};

const searchBatchTransactions = async ({
  searchField = ['all'],
  searchValue = ['-1'],
  currentPage = 0,
  resultsPerPage = 100,
  sortBy = 'id',
  sortAscending = 0,
}) => {
  const requestPayload = {
    request: 'search_batch_payment_transactions',
    api: "4.1",
    search_field: searchField,
    search_value: searchValue,
    current_page: currentPage,
    results_per_page: resultsPerPage,
    sort_by: sortBy,
    sort_ascending: sortAscending,
  };

  return await client
    .sendRequest(URL, requestPayload)
    .then(response => {
      if (response.status !== 0) {
        const error = new Error(response.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response } } });
        return error;
      }
      const merchantService = response.output;

      if (!merchantService) {
        return new Error('Service not found');
      }
      return merchantService;
    })
    .catch(error => {
      notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response: error.response } } });
      return error;
    });
};

const fetchLandingPageServicesForMerchant = async merchantSrvrtid => {
  const requestPayload = {
    request: 'search_merchant_services',
    api: "4.1",
    search_field: ['merchant_srvrtid', 'status', 'merchant_landing_display'],
    search_value: [merchantSrvrtid, 'ACTIVE', 'TRUE'],
  };

  return await client
    .sendRequest(URL, requestPayload)
    .then(response => {
      if (response.status !== 0) {
        const error = new Error(response.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response } } });
        return [];
      }
      return response.output;
    })
    .catch(error => {
      notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response: error.response } } });
      return error;
    });
};

const submitBulkPaymentsFile = async params => {
  const { file, wallet, description } = params;

  const payload = {
    request: 'upload_batch_payment_file',
    batch_payment_file: file,
    wallet_srvrtid: wallet,
    batch_payment_description: description,
  };
  console.log('The file is here', payload);

  return await client
    .uploadFile(URL, payload)
    .then(response => {
      if (response.status !== 0) {
        const error = new Error(response.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: payload, response } } });
        throw error;
      }
      return response;
    })
    .catch(e => {
      notifyBugsnag({ error: e, metaData: { scope: 'API REQUEST', xhr_data: { request: payload, response: e.response } } });
      throw e;
    });
};

const uploadBatchPayment = async params => {
  const requestPayload = {
    request: 'upload_batch_payment',
    api: "4.1",
  };

  return await client
    .sendRequest(URL, requestPayload)
    .then(response => {
      if (response.status !== 0) {
        const error = new Error(response.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response } } });
      return error;
      }
      return response;
    })
    .catch(e => {
        notifyBugsnag({ error: e, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response: e.response } } });
      throw e;
    });
};

const getBulkPaymentsFileDetails = async params => {
  const { batch_payment_upload_file_srvrtid, current_page = 0 } = params || {};

  const payload = {
    request: 'view_batch_payment_transactions',
    batch_payment_upload_file_srvrtid,
    current_page,
  };

  return await client
    .sendRequest(URL, payload)
    .then(response => {
      if (response.status !== 0) {
        const error = new Error(response.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: payload, response } } });
      return error;
      }

      return response;
    })
    .catch(e => {
        notifyBugsnag({ error: e, metaData: { scope: 'API REQUEST', xhr_data: { request: payload, response: e.response } } });
      throw e;
    });
};

const processBatchPayment = async params => {
  const { batch_payment_upload_file_srvrtid } = params || {};

  const payload = {
    request: 'process_batch_payment',
    batch_payment_upload_file_srvrtid,
  };

  return await client
    .sendRequest(URL, payload)
    .then(response => {
      if (response.status !== 0) {
        const error = new Error(response.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: payload, response } } });
      return error;
      }

      return response.output;
    })
    .catch(e => {
        notifyBugsnag({ error: e, metaData: { scope: 'API REQUEST', xhr_data: { request: payload, response: e.response } } });
      throw e;
    });
};

const downloadTransactionsFile = async params => {
  const { batch_payment_upload_file_srvrtid } = params;

  const payload = {
    request: 'get_upload_batch_transactions',
    batch_payment_upload_file_srvrtid,
  };

  return await client
    .sendRequest(URL, payload)
    .then(response => {
      if (response.status !== 0) {
        const error = new Error(response.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: payload, response } } });
      return error;
      }

      // URL?request=download_batch_transactions&token=${token}. open in new tab
      const urlToDownloadFile = `${URL}?request=download_batch_transactions&token=${response?.token}`;
      window.open(urlToDownloadFile, '_blank');
      return response;
    })
    .catch(e => {
        notifyBugsnag({ error: e, metaData: { scope: 'API REQUEST', xhr_data: { request: payload, response: e.response } } });
      throw e;
    });
};
const downloadFailedTransactionsFile = async batch_payment_upload_file_srvrtid => {

  const payload = {
    request: 'get_failed_batch_transactions',
    batch_payment_upload_file_srvrtid,
  };

  return await client
    .sendRequest(URL, payload)
    .then(response => {
      if (response.status !== 0) {
        const error = new Error(response.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: payload, response } } });
      return error;
      }

      // URL?request=download_batch_transactions&token=${token}. open in new tab
      const urlToDownloadFile = `${URL}?request=download_failed_batch_transactions&token=${response?.token}`;
      window.open(urlToDownloadFile, '_blank');
      return response;
    })
    .catch(e => {
        notifyBugsnag({ error: e, metaData: { scope: 'API REQUEST', xhr_data: { request: payload, response: e.response } } });
      throw e;
    });
};

const downloadTransactionDetailsFile = async batch_payment_upload_file_srvrtid => {

  const payload = {
    request: 'get_upload_batch_transactions',
    batch_payment_upload_file_srvrtid,
  };

  return await client
    .sendRequest(URL, payload)
    .then(response => {
      if (response.status !== 0) {
        const error = new Error(response.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: payload, response } } });
      return error;
      }

      // URL?request=download_batch_transactions&token=${token}. open in new tab
      const urlToDownloadFile = `${URL}?request=download_batch_upload_info&token=${response?.token}`;
      window.open(urlToDownloadFile, '_blank');
      return response;
    })
    .catch(e => {
        notifyBugsnag({ error: e, metaData: { scope: 'API REQUEST', xhr_data: { request: payload, response: e.response } } });
      throw e;
    });
};

const cancelUpload = async params => {
  const { batch_payment_upload_file_srvrtid } = params;

  const payload = {
    request: 'cancel_upload',
    batch_payment_upload_file_srvrtid,
  };

  return await client
    .sendRequest(URL, payload)
    .then(response => {
      if (response.status !== 0) {
        const error = new Error(response.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: payload, response } } });
      return error;
      }

      return response;
    })
    .catch(e => {
        notifyBugsnag({ error: e, metaData: { scope: 'API REQUEST', xhr_data: { request: payload, response: e.response } } });
      throw e;
    });
};

const generateInvoice = async params => {
  const { batch_payment_upload_file_srvrtid } = params;

  const payload = {
    request: 'generate_invoice',
    batch_payment_upload_file_srvrtid,
  };

  return await client
    .sendRequest(URL, payload)
    .then(response => {
      if (response.status !== 0) {
        const error = new Error(response.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: payload, response } } });
      return error;
      }

      const urlToOpen = `${URL}?request=download_invoice&token=${response.token}`;
      window.open(urlToOpen, '_blank');
      return response;
    })
    .catch(e => {
        notifyBugsnag({ error: e, metaData: { scope: 'API REQUEST', xhr_data: { request: payload, response: e.response } } });
      throw e;
    });
};

const generateBalanceActivityReport = async params => {
  const { batch_payment_upload_file_srvrtid } = params;

  const payload = {
    request: 'get_upload_batch_transactions',
    batch_payment_upload_file_srvrtid,
  };

  return await client
    .sendRequest(URL, payload)
    .then(response => {
      if (response.status !== 0) {
        const error = new Error(response.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: payload, response } } });
      return error;
      }

      const urlToOpen = `${URL}?request=download_balance_activity_report&token=${response.token}`;
      window.open(urlToOpen, '_blank');
      return response;
    })
    .catch(e => {
        notifyBugsnag({ error: e, metaData: { scope: 'API REQUEST', xhr_data: { request: payload, response: e.response } } });
      throw e;
    });
};

module.exports = {
  fetchLandingPageServicesForMerchant,
  findBySrvrtid,
  searchBatchTransactions,
  search,
  submitBulkPaymentsFile,
  getBulkPaymentsFileDetails,
  processBatchPayment,
  downloadTransactionsFile,
  cancelUpload,
  generateInvoice,
  generateBalanceActivityReport,
  uploadBatchPayment,
  downloadFailedTransactionsFile,
  downloadTransactionDetailsFile,
};
